import React from 'react';
import {graphql} from 'gatsby';
import {Swiper, SwiperSlide} from 'swiper/react';

import {Breadcrumb, Card, Layout, Seo, Share} from '@app/components';

type PageTemplatePropTypes = {
  data: {
    allMarkdownRemark: {
      nodes: {
        excerpt: string;
        frontmatter: {
          title: string;
          author: string;
          cover: any;
        };
      }[];
    };
  };
  pageContext: {
    author: string;
  };
};

const PageTemplate: React.FC<PageTemplatePropTypes> = ({data, pageContext}) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title={pageContext.author} />
      <Breadcrumb title={pageContext.author} />
      <div className="container mx-auto px-4 py-20 lg:px-0 xl:max-w-container-xl">
        <div className="grid lg:grid-cols-2 lg:gap-x-10 gap-y-20">
          {posts.slice(0, 2).map((post) => (
            <Card
              title={post.frontmatter.title}
              description={post.excerpt}
              author={post.frontmatter.author}
              cover={post.frontmatter.cover?.childImageSharp?.fluid}
              key={post.frontmatter.title}
            />
          ))}
        </div>
        <div className="grid lg:grid-cols-3 lg:gap-x-10 gap-y-20 mt-20">
          {posts.slice(2, 11).map((post) => (
            <Card
              title={post.frontmatter.title}
              description={post.excerpt}
              author={post.frontmatter.author}
              cover={post.frontmatter.cover?.childImageSharp?.fluid}
              key={post.frontmatter.title}
            />
          ))}
        </div>
      </div>

      {posts.slice(11).length > 0 && (
        <div className="py-20">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
              '768': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '1024': {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              '1280': {
                slidesPerView: 9,
                spaceBetween: 20,
              },
            }}
          >
            {posts.slice(11).map((post) => (
              <SwiperSlide key={post.frontmatter.title}>
                <Card
                  title={post.frontmatter.title}
                  cover={post.frontmatter?.cover?.childImageSharp?.fluid}
                  author={post.frontmatter.author}
                  isSmall
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      <div className="container mx-auto px-4 py-2.5 lg:px-0 xl:max-w-container-xl">
        <Share title={pageContext.author} />
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($author: String!, $language: String!) {
    allMarkdownRemark(
      filter: {frontmatter: {author: {eq: $author}, language: {eq: $language}}}
    ) {
      nodes {
        excerpt(pruneLength: 140)
        frontmatter {
          title
          author
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
